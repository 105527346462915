import { render, staticRenderFns } from "./payroll.vue?vue&type=template&id=88150dde&scoped=true"
import script from "./payroll.vue?vue&type=script&lang=js"
export * from "./payroll.vue?vue&type=script&lang=js"
import style0 from "./payroll.vue?vue&type=style&index=0&id=88150dde&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88150dde",
  null
  
)

export default component.exports
<template>
  <span>
    <div class="block">
      <el-form class="top-from">
        <el-form-item label="Period">
          <el-select v-model="period" class="per-select"  placeholder="请选择" @change="periodChange">
            <el-option
              v-for="item in periods"
              :key="item.id"
              :label="item.periodName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-upload
        class="upload-demo"
        :drag="true"
        :auto-upload="false"
        action=""
        :multiple="false"
        :show-file-list="true"
        :file-list="fileList"
        :onChange="handleChange"
        ref="up"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传Excel文件，且不超过5M
        </div>
      </el-upload>
    </div>
  </span>
</template>

<script>
export default {
  name: 'PrUploadFile',

  props: {
    periods: Array
  },

  data() {
    return {
      fileList: [],
      period: '',
      file:{}
    }
  },

  mounted(){
    this.$refs.up.clearFiles()
  },

  methods: {
    handleChange(file) {
      this.file=file
      this.$emit('onChange', file, this.period)
    },

    periodChange(){
       this.$emit('onChange', this.file, this.period)
    },
    clearFiles() {
      this.$refs.up.clearFiles();
    }
  }
}
</script>
<style lang="scss" scoped>
.top-from{
  margin-bottom: 20px;
  ::v-deep .el-select {
    margin-left: 12px;
  }
}
</style>

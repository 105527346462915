<template>
  <div class="actDataBody">
    <PocCardTable class="actData">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="100000000"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          :align="item.align"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            <div>
              <div v-if="item.key === 'submitStatus'" class="statusView">
                <span
                  class="dot"
                  :class="{
                    dotBg1: scope.row[item.key] === '未提交',
                    dotBg2: scope.row[item.key] === '未通过',
                    dotBg3: scope.row[item.key] === '已提交',
                    dotBg4: scope.row[item.key] === '已通过'
                  }"
                ></span
                >{{ scope.row[item.key] }}
              </div>
              <div v-else-if="item.key === 'computeStatus'" class="statusView">
                <span
                  class="dot"
                  :class="{
                    dotBg1: scope.row[item.key] === '未计算',
                    dotBg4: scope.row[item.key] === '已计算'
                  }"
                ></span
                >{{ scope.row[item.key] }}
              </div>
              <div v-else>{{ scope.row[item.key] }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Operations"
          width="260"
          fixed="right"
          align="right"
        >
          <template slot-scope="scope">
            <el-tooltip content="详情" placement="top">
              <img
                class="iconImg pointer"
                src="@/assets/img/check.png"
                @click.stop="cat(scope.row)"
              />
            </el-tooltip>
            <el-tooltip content="计算" placement="top">
              <img
                class="iconImg pointer"
                src="@/assets/img/calculate.png"
                @click.stop="computePeriod(scope.row)"
              />
            </el-tooltip>
            <el-tooltip content="通过" placement="top">
              <img
                class="iconImg pointer"
                src="@/assets/img/adopt.png"
                @click.stop="submit(scope.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <el-dialog
      class="periodDialog"
      title="创建Period"
      :visible.sync="periodDialogVisible"
      width="20%"
      top="150px"
      @close="handleClose"
    >
      <span>
        <div class="block">
          <span class="demonstration">*Period</span>
          <el-date-picker v-model="newPeriod" type="month" placeholder="选择月">
          </el-date-picker>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="periodDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createNewPeriod">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ActDataTableColumn } from '@/views/payroll/TableColumn'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import { getPeriodList, addPeriod, compute, periodSubmit } from '@/api/payroll'

import PocSlider from '@/components/poc-slider/poc-slider'

const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  props: {
    formInline: {
      type: Object,
      default: {
        uploadStatus: '',
        submitStatus: '',
        creatorId: '',
        creatorName: '',
        periodFrom: '',
        periodEnd: '',
        pageSize: 10,
        pageNum: 1,
        type: 1
      }
    }
  },
  components: {
    PocSlider,
    PersonnelSelectionDialog
  },
  data() {
    return {
      loading: false,
      columnConfig: ActDataTableColumn,
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      updateStatus: [
        {
          label: 'Active',
          value: 'Active'
        },
        {
          label: 'Leaver',
          value: 'Leaver'
        },
        {
          label: 'TBH (New Hire)',
          value: 'TBH (New Hire)'
        },
        {
          label: 'TBH (Replacement)',
          value: 'TBH (Replacement)'
        },
        {
          label: 'Transfer In',
          value: 'Transfer In'
        },
        {
          label: 'Transfer Out',
          value: 'Transfer Out'
        },
        {
          label: 'Recruiting',
          value: 'Recruiting'
        }
      ],
      submitStatus: [
        {
          label: '未提交',
          value: '0'
        },
        {
          label: '已提交',
          value: '1'
        }
      ],
      ruleForm: {},
      targetObj: '',
      toObj: '',
      periodDialogVisible: false,
      newPeriod: '',
      formStatus: false,
      periodRange: [],
      hrbpClear: ''
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 330
    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {},

    search() {
      this.loading = true
      getPeriodList(this.formInline).then((res) => {
        this.tableData = res.data.list
        this.pageConfig = res.data
        this.loading = false
      })
    },

    queryApi() {
      this.search()
    },

    clear() {
      this.hrbpClear = true
      this.formInline.creatorId = ''
      this.formInline.creatorName = ''
    },

    periodClear() {
      this.formInline.periodFrom = ''
      this.formInline.periodEnd = ''
    },

    selectPersonal(val1, val2, val3) {
      if (this.hrbpClear) {
        this.hrbpClear = false
        return
      }
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3
        })
      }
    },

    onConfirm(val) {
      if (val && val.length) {
        let data = val[0]
        this.formInline[this.toObj] = data.id
        this.formInline[this.targetObj] = data.userName
      }
    },

    createPeriod() {
      this.periodDialogVisible = true
    },

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.pageConfig.pageNum = val
      this.queryApi()
    },

    handleClose() {
      this.periodDialogVisible = false
    },

    cat(val) {
      this.$router.replace({
        path: 'actDataDetail',
        query: {
          id: val.id,
          position: 'offer',
          title: 'Offer 信息'
        }
      })
    },

    createNewPeriod() {
      if (!this.newPeriod) {
        this.$message.success('请选择Period')
        return
      }
      const data = {
        period: this.newPeriod
      }
      addPeriod(data).then((res) => {
        this.periodDialogVisible = false
        setTimeout(() => {
          this.search()
        }, 500)
      })
    },

    periodChange(val) {
      if (val != null) {
        this.formInline.periodFrom = this.periodRange[0]
        this.formInline.periodEnd = this.periodRange[1]
      }

      if (val == null) {
        this.formInline.periodFrom = ''
        this.formInline.periodEnd = ''
      }
    },

    computePeriod(val) {
      if (val.computeStatus == 1) {
        this.$message('已完成计算，再次计算会覆盖原来计算值')
      }

      const data = {
        period: val.id
      }

      compute(data).then((res) => {
        if (res && res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
        }
        this.search()
      })
    },

    submit(val) {
      const data = {
        payrollPeriod: val.id
      }

      periodSubmit(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message.error(res.msg)
        }
        this.search()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.actDataBody {
  overflow-x: hidden;
  .actData {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
  .periodDialog {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC, serif;
    font-weight: 500;
    .demonstration {
      margin: 10px;
    }
  }
  .iconImg {
    width: 16px;
    height: 16px;
    margin-left: 16px;
  }
  .pointer {
    cursor: pointer;
  }
  .dot {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    top: 7px;
    left: -10px;
  }
  .dotBg1 {
    background: #e6a23c;
  }
  .dotBg2 {
    background: #de3934;
  }
  .dotBg3 {
    background: #2490e3;
  }
  .dotBg4 {
    background: #6ebaaf;
  }
  .statusView {
    display: flex;
    justify-content: flex-start;
  }
}
</style>

<template>
  <el-dialog 
    title="选择下载的模板" 
    :visible.sync="templateVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="closeTemplate"
  >
    <el-radio-group v-model="templateType" class="template-ridio" size="medium">
      <el-radio :label="0">旧模板（适用于2022年5月份及之前）</el-radio>
      <el-radio :label="1">新模板</el-radio>
    </el-radio-group>
    <template slot="footer">
      <el-button @click="closeTemplate">取消</el-button>
      <el-button type="primary" @click="confirmTemplate">确认</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { ref, defineProps, onMounted, defineEmits, watch } from 'vue';
  const props = defineProps({
    visible: {
      type: Boolean,
      default: false
    }
  })
  const emits = defineEmits(['close', 'confirm'])
  // data
  let templateVisible = ref(false);
  let templateType = ref(1);

  watch(()=>props.visible,(c, o) =>{
    templateVisible.value = c
  })

  const closeTemplate = () => {
    emits('close', false)
  }
  const confirmTemplate = () => {
    emits('confirm', templateType.value)
  }
</script>

<style lang="scss" scoped>
:deep(.template-ridio){
  display: flex;
  flex-direction: column;
  font-size: 20px;
}
:deep(.el-radio:nth-child(1)){
  margin-bottom: 10px;
}
:deep(.el-radio-group .el-radio .el-radio__label){
  font-size: 14px!important;
}
:deep(.el-radio-group .el-radio .el-radio__inner){
  width: 16px;
  height: 16px;
}
</style>
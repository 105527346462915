var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contractorPaymentDataBody"},[_c('PocCardTable',{staticClass:"contractorPaymentData"},[_c('PocTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"tTableRef",attrs:{"data":_vm.tableData,"current-page":_vm.pageConfig.pageNum,"total":_vm.pageConfig.total,"stripe":"","size":"large","max-height":10000000000,"highlight-current-row":""},on:{"update:currentPage":function($event){return _vm.$set(_vm.pageConfig, "pageNum", $event)},"update:current-page":function($event){return _vm.$set(_vm.pageConfig, "pageNum", $event)},"size-change":_vm.pageSizeChange,"page-current-change":_vm.pageChange}},[_vm._l((_vm.columnConfig),function(item){return _c('el-table-column',{key:item.key,attrs:{"prop":item.key,"label":item.name,"fixed":item.fixed,"sortable":item.sortable,"width":item.key === 'no' ? 60 : null,"min-width":item.width,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[(item.key === 'submitStatus')?_c('div',{staticClass:"statusView"},[_c('span',{staticClass:"dot",class:{
                  dotBg1: scope.row[item.key] === '未提交',
                  dotBg2: scope.row[item.key] === '未通过',
                  dotBg3: scope.row[item.key] === '已提交',
                  dotBg4: scope.row[item.key] === '已通过'
                }}),_vm._v(_vm._s(scope.row[item.key])+" ")]):(item.key === 'computeStatus')?_c('div',{staticClass:"statusView"},[_c('span',{staticClass:"dot",class:{
                  dotBg1: scope.row[item.key] === '未计算',
                  dotBg4: scope.row[item.key] === '已计算'
                }}),_vm._v(_vm._s(scope.row[item.key])+" ")]):_c('div',[_vm._v(_vm._s(scope.row[item.key]))])])]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"Operations","width":"100","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"content":"详情","placement":"top"}},[_c('img',{staticClass:"iconImg pointer",attrs:{"src":require("@/assets/img/check.png")},on:{"click":function($event){$event.stopPropagation();return _vm.cat(scope.row)}}})])]}}])})],2)],1),_c('el-dialog',{staticClass:"fileUpload",attrs:{"title":"文件上传","visible":_vm.fileUploadVisible,"width":"30%","top":"150px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.fileUploadVisible=$event}}},[_c('PrUploadFile',{attrs:{"periods":_vm.periods},on:{"onChange":_vm.fileChange}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.fileUploadVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.fileUpload}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
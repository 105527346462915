<template>
  <div class="internManage">
    <PocCardTable class="topPocCardTable">
      <template>
        <div class="flexBetw opeartionBox">
          <div class="pcth-title-left">Payroll Act</div>
          <div class="selectTab1" ref="selectTab1" @mouseover.stop="toggleMenu()">
            <el-select
              v-model="activeName"
              class="selectView"
              ref="selectTab"
              @change="resetPage"
            >
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flexBetw" style="width: 400px">
            <div></div>
            <div>
              <template v-if="activeName != 'actData'">
                <el-button @click="showTemDialog"  v-if="['payrollReport','FTEPayment'].includes(activeName)">模板下载</el-button>
                <el-button @click="templateDownload"  v-else>模板下载</el-button>
              </template>
              <el-button @click="search">查询</el-button>
              <el-button
                @click="createPeriod"
                type="primary"
                v-if="activeName === 'actData'"
                >创建Period</el-button
              >
              <el-button @click="upload" v-else type="primary">上传</el-button>
            </div>
          </div>
        </div>
      </template>
      <template>
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          class="personnelRequisitionForm"
        >
          <el-form-item label="Period">
            <el-date-picker
              style="width: 100%"
              v-model="periodRange"
              type="monthrange"
              align="right"
              unlink-panels
              clearable
              range-separator="To"
              start-placeholder="Start Month"
              end-placeholder="End Month"
              @change="periodChange"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="Creator" prop="creator">
            <el-input
              :readonly="!formStatus"
              :disabled="formStatus"
              v-model="formInline.creatorName"
              clearable
              @click.native.stop="
                selectPersonal('creatorName', 'creatorId', 'PAYROLL')
              "
              placeholder="请选择"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-circle-close"
                @click="clear()"
                class="btn-clear"
              ></el-button>
            </el-input>
          </el-form-item>

          <el-form-item label="Upload Status" v-if="activeName !== 'actData'">
            <el-select
              v-model="formInline.uploadStatus"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in uploadStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Submit Status">
            <el-select
              v-model="formInline.submitStatus"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in submitStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <PersonnelSelectionDialog
          ref="PersonnelSelectionDialogRef"
          @onConfirm="onConfirm"
        />
      </template>
    </PocCardTable>
    <component :is="activeName" ref="activeRef" :formInline="formInline" />
    <TemplateDownload :visible="downloadShow" @close="closeTemDialog" @confirm="confirmTem"/>
  </div>
</template>

<script>
import actData from '@/views/payroll/components/actData.vue'
import payrollReport from '@/views/payroll/components/payrollReport.vue'
import allowanceReport from '@/views/payroll/components/allowanceReport.vue'
import annualLeaveReport from '@/views/payroll/components/annualLeaveReport.vue'
import FTEPayment from '@/views/payroll/components/FTEPayment.vue'
import contractorPayment from '@/views/payroll/components/contractorPayment.vue'
import freelancePayment from '@/views/payroll/components/freelancePayment.vue'
import internPayroll from '@/views/payroll/components/internPayroll.vue'
import TemplateDownload from '@/views/payroll/components/templateDownload.vue'

import { templateExport } from '@/api/payrollReport'

import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import { stampToTime } from '@/utils/util'

const h = document.getElementsByTagName('body')[0].clientHeight - 760 + 'px'

export default {
  name: 'internManage',
  components: {
    PersonnelSelectionDialog,
    actData,
    payrollReport,
    allowanceReport,
    annualLeaveReport,
    FTEPayment,
    contractorPayment,
    freelancePayment,
    internPayroll,
    TemplateDownload
  },
  data() {
    return {
      activeName: 'actData',
      selectOptions: [
        {
          label: 'Act Data',
          value: 'actData'
        },
        {
          label: 'Payroll Report',
          value: 'payrollReport'
        },
        {
          label: 'Allowance Report',
          value: 'allowanceReport'
        },
        {
          label: 'AnnualLeave Report',
          value: 'annualLeaveReport'
        },
        {
          label: 'FTE Payment',
          value: 'FTEPayment'
        },
        {
          label: 'Contractor Payment',
          value: 'contractorPayment'
        },
        {
          label: 'Freelance Payment',
          value: 'freelancePayment'
        },
        {
          label: 'Intern Payroll',
          value: 'internPayroll'
        }
      ],
      formStatus: false,
      periodRange: '',
      uploadStatus: [
        {
          label: '上传成功',
          value: '0'
        },
        {
          label: '上传失败',
          value: '1'
        }
      ],
      submitStatus: [
        {
          label: '未提交',
          value: '0'
        },
        {
          label: '已提交',
          value: '1'
        }
      ],
      formInline: {
        uploadStatus: '',
        submitStatus: '',
        creatorId: '',
        creatorName: '',
        periodFrom: '',
        periodEnd: '',
        pageSize: 10,
        pageNum: 1,
        type: 1
      },
      toObj: '',
      targetObj: '',

      downloadShow: false
    }
  },
  mounted() {
    // this.$refs.selectTab1.addEventListener('mouseover',()=>{
    //   console.log(this.$refs.selectTab)
    //   this.$nextTick(()=>{
    //     this.$refs.selectTab.focus()
    //     this.$refs.selectTab.toggleMenu()
    //   })
    // })
    // this.$refs.selectTab1.addEventListener('mouseleave',()=>{
    //   this.$nextTick(()=>{
    //     this.$refs.selectTab.blur()
    //   })
    // })
  },
  methods: {
    queryApi(val = {}) {},
    toggleMenu() {
      // this.$refs.selectTab.focus()
      // this.$refs.selectTab.toggleMenu()
    },
    resetPage() {
      this.formInline.pageNum = 1
    },
    handleSelectionChange(val) {
      this.selectVal = val
    },
    search() {
      this.$refs.activeRef.search()
    },

    showTemDialog() {
      this.downloadShow = true;
    },
    closeTemDialog() {
      this.downloadShow = false;
    },
    confirmTem(val) {
      val == 0 ? this.templateDownload() : this.newTemplateDownload();
      this.closeTemDialog();
    },
    newTemplateDownload() {
      templateExport(this.activeName+ 'V2')
    },
    templateDownload(){
      templateExport(this.activeName)
    },
    createPeriod() {
      this.$refs.activeRef.createPeriod()
    },
    download() {
      this.$refs.activeRef.download()
    },
    upload() {
      this.$refs.activeRef.upload()
    },
    clear() {
      this.hrbpClear = true
      this.formInline.creatorId = ''
      this.formInline.creatorName = ''
    },
    periodChange(val) {
      if (val != null) {
        const date = new Date(val[1])
        const enddate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        this.formInline.periodFrom = this.periodRange[0]
        this.formInline.periodEnd = enddate
      }
      if (val == null) {
        this.formInline.periodFrom = ''
        this.formInline.periodEnd = ''
      }
    },
    selectPersonal(val1, val2, val3) {
      if (this.hrbpClear) {
        this.hrbpClear = false
        return
      }
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3,
          radio: true
        })
      }
    },
    onConfirm(val) {
      console.log(val)
      if (val && val.length) {
        let data = val[0]
        this.formInline[this.toObj] = data.id
        this.formInline[this.targetObj] = data.userName
      }
    }
  },

  created() {
    let tabName = this.$route.query.title
    if (tabName) {
      this.activeName = tabName
    }
  }
}
</script>

<style lang="scss" scoped>
.opeartionBox{
  position: relative;
  .selectTab1{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
  }
}
.internManage {
  overflow-x: hidden;
  overflow-y: hidden;
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  .topPocCardTable {
    padding-top: 20px !important;
    ::v-deep .selectView .el-input--mini .el-input__inner {
      background: #f4f4f4;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: #1b1d1e;
      font-family: Roboto-Medium, Roboto;
    }
  }
  .selectView {
    height: 27px;
    background: #f4f4f4;
    border-radius: 4px;
    width: 190px;
  }
  .btn-clear {
    width: 8px;
  }
  ::v-deep .el-form .el-form-item {
    margin: 16px 16px 0 0 !important;
  }
  ::v-deep .el-input-group__append {
    width: 8px;
    padding: 0;
  }
}
</style>

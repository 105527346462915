<template>
  <div class="FETPaymentDataBody">
    <PocCardTable class="FETPaymentData">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="10000000000"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            <div>
              <div v-if="item.key === 'submitStatus'" class="statusView">
                <span
                  class="dot"
                  :class="{
                    dotBg1: scope.row[item.key] === '未提交',
                    dotBg2: scope.row[item.key] === '未通过',
                    dotBg3: scope.row[item.key] === '已提交',
                    dotBg4: scope.row[item.key] === '已通过'
                  }"
                ></span
                >{{ scope.row[item.key] }}
              </div>
              <div v-else-if="item.key === 'computeStatus'" class="statusView">
                <span
                  class="dot"
                  :class="{
                    dotBg1: scope.row[item.key] === '未计算',
                    dotBg4: scope.row[item.key] === '已计算'
                  }"
                ></span
                >{{ scope.row[item.key] }}
              </div>
              <div v-else>{{ scope.row[item.key] }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Operations"
          width="100"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip content="详情" placement="top">
              <img
                class="iconImg pointer"
                src="@/assets/img/check.png"
                @click.stop="cat(scope.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <el-dialog
      class="fileUpload"
      title="文件上传"
      :visible.sync="fileUploadVisible"
      width="30%"
      top="150px"
      :before-close="handleClose"
    >
      <PrUploadFile
        v-bind:periods="periods"
        @onChange="fileChange"
      ></PrUploadFile>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fileUploadVisible = false">取 消</el-button>
        <el-button type="primary" @click="fileUpload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { FTEPaymentReportTableColumn } from '@/views/payroll/TableColumn'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import PrUploadFile from '@/components/pg-uploadFile/pr-upload'
import { getPeriodUpLoadList, getAllPeriodList } from '@/api/payroll'

import { uploadFTEFile } from '@/api/payrollReport'

import PocSlider from '@/components/poc-slider/poc-slider'
import { fromData } from 'ssri'
const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  props: {
    formInline: {
      type: Object,
      default: {
        uploadStatus: '',
        submitStatus: '',
        creatorId: '',
        creatorName: '',
        periodFrom: '',
        periodEnd: '',
        pageSize: 10,
        pageNum: 1,
        type: 3
      }
    }
  },
  components: {
    PocSlider,
    PersonnelSelectionDialog,
    PrUploadFile
  },

  data() {
    return {
      loading: false,
      columnConfig: FTEPaymentReportTableColumn,
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      periods: [],
      uploadStatus: [
        {
          label: '上传成功',
          value: '0'
        },
        {
          label: '上传失败',
          value: '1'
        }
      ],
      submitStatus: [
        {
          label: '未提交',
          value: '0'
        },
        {
          label: '已提交',
          value: '1'
        }
      ],
      targetObj: '',
      toObj: '',
      fileUploadVisible: false,
      formStatus: false,
      periodRange: [],
      uploadfile: '',
      filePeriod: ''
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 330
    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {
      getAllPeriodList().then((res) => {
        this.periods = res.data
      })
    },

    search() {
      this.formInline.type = 3
      this.loading = true
      getPeriodUpLoadList(this.formInline).then((res) => {
        this.tableData = res.data.list
        this.pageConfig = res.data
        this.loading = false
      })
    },

    queryApi() {
      this.search()
    },

    clear() {
      this.hrbpClear = true
      this.formInline.creatorId = ''
      this.formInline.creatorName = ''
    },
    handleClose() {
      this.fileUploadVisible = false
    },
    selectPersonal(val1, val2, val3) {
      if (this.hrbpClear) {
        this.hrbpClear = false
        return
      }
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3
        })
      }
    },

    onConfirm(val) {
      if (val && val.length) {
        let data = val[0]
        this.formInline[this.toObj] = data.id
        this.formInline[this.targetObj] = data.userName
      }
    },

    createPeriod() {
      this.fileUploadVisible = true
    },

    periodChange(val) {
      if (val != null) {
        this.formInline.periodFrom = this.periodRange[0]
        this.formInline.periodEnd = this.periodRange[1]
      }

      if (val == null) {
        this.formInline.periodFrom = ''
        this.formInline.periodEnd = ''
      }
    },

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.pageConfig.pageNum = val
      this.queryApi()
    },

    cat(val) {
      this.$router.replace({
        path: 'FETPaymentDetail',
        query: {
          id: val.id,
          position: 'offer',
          title: 'Offer 信息',
          period: val.period
        }
      })
    },

    fileChange(val, period) {
      this.uploadfile = val
      this.filePeriod = period
    },
    upload() {
      this.fileUploadVisible = true
    },
    fileUpload(val) {
      if (!this.uploadfile) {
        this.$message.success('请选择要上传的文件')
        return
      }
      if (!this.filePeriod) {
        this.$message.success('请选择Period')
        return
      }
      const data = new FormData()
      data.append('file', this.uploadfile.raw)
      data.append('period', this.filePeriod)

      uploadFTEFile(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } 
        // else {
        //   this.$message.error(res.msg)
        // }
        setTimeout(() => {
          this.search()
        }, 1000)
        this.fileUploadVisible = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.FETPaymentDataBody {
  overflow-x: hidden;
  .FETPaymentData {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
  .fileUpload {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC, serif;
    font-weight: 500;
    .demonstration {
      margin: 10px;
    }
  }
  .dot {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    top: 7px;
    left: -10px;
  }
  .dotBg1 {
    background: #e6a23c;
  }
  .dotBg2 {
    background: #de3934;
  }
  .dotBg3 {
    background: #2490e3;
  }
  .dotBg4 {
    background: #6ebaaf;
  }
  .statusView {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
